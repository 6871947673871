import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import PostList from '../components/PostList'
import ProjectList from '../components/ProjectList'
import Bio from '../components/Bio'
import Layout from "../components/layout"
import { rhythm } from '../utils/typography'

const BlogIndex = (props) => {
  const siteTitle = props.data.site.siteMetadata.title;
  const posts = props.data.allMarkdownRemark.edges;

  const projects = posts.filter((p) => p.node.frontmatter.project).slice(0, 4)
  const articles = posts.filter((p) => !p.node.frontmatter.project)

    return (
      <Layout>
        <Bio />
        <Helmet title={siteTitle} />
        <h2
          css={{
            fontSize: `1rem`,
            marginTop: rhythm(1),
            marginBottom: rhythm(1/2),
            display: 'flex'
          }}
        >
        Latest Projects
        <Link 
          to={'/projects'}
          css={{
            marginLeft: 'auto'
          }}
        >See All</Link>
        </h2>
        <ProjectList postEdges={projects} />
        <hr 
          css={{
            marginTop: rhythm(1/2),
            marginBottom: rhythm(2),
          }}
        />
        <h2
          css={{
            fontSize: `1rem`,
            marginTop: rhythm(1),
            marginBottom: rhythm(1/2),
            display: 'flex'
          }}
        >
        Recent Articles
        <Link 
          to={'/articles'}
          css={{
            marginLeft: 'auto'
          }}
        >See All</Link>
        </h2>
        <PostList postEdges={articles} />
      </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
  }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            project
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
