import React, { Component } from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { rhythm } from '../utils/typography'
import presets, { colors } from '../utils/presets'

class PostList extends Component {
  render() {
    const posts = this.props.postEdges
    return (
      <section
        css={{
          display: `grid`,
          gridTemplateColumns: `1fr`,
          gridColumnGap: rhythm(1),
          gridRowGap: rhythm(1),
        }}
      >
        {posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          return (
            <div
              key={node.fields.slug}
              css={{
                display: `grid`,
                gridTemplateColumns: `1fr`,
                gridColumnGap: rhythm(1),
                borderBottom: `1px solid ${colors.ui.light}`,
                [presets.Tablet]: {
                  gridTemplateColumns: `3fr 1fr`,
                  paddingTop: rhythm(1),
                  paddingBottom: rhythm(1),
                },
              }}
            >
              <div
                css={{
                  display: `grid`,
                  gridTemplateColumns: `1fr`,
                  gridColumnGap: rhythm(1),
                  order: `2`,
                  // marginBottom: rhythm(1),
                  [presets.Tablet]: {
                    marginBottom: `0`,
                    order: `-1`,
                  },
                }}
              >
                <h3
                  css={{
                    marginTop: rhythm(1),
                    marginBottom: rhythm(1 / 4),
                    [presets.Tablet]: {
                      marginTop: 0,
                      // marginBottom: 0,
                    },
                  }}
                >
                  <Link
                    css={{
                      order: `1`,
                      [presets.Tablet]: {
                        order: `2`,
                        marginBottom: `0`,
                      },
                    }}
                    className="link-style"
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
                <small
                  css={{
                    color: colors.ui.calm,
                  }}
                >
                  {node.frontmatter.date}
                </small>
                {/* <p dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                {/* <span>
                  <Link to={node.fields.slug}>Read Article</Link>
                </span> */}
              </div>

              {/* <Img
                fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                imgStyle={{ marginBottom: '0' }}
              /> */}
            </div>
          )
        })}
      </section>
    )
  }
}

export default PostList
